import { useEffect } from 'react';

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';

// Services
import siteServices from '/services/site';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Link from '@wearetla/tla-essentials-tools/partials/link';

export const TopBanner = () => {
	const { isApplication, topBannerData, setData } = useGlobalState();
	const { mobile } = useBreakpoints();
	const { activeRoute } = useNavigator();

	useEffect(() => {
		if(activeRoute?.key === 'home' && !(isApplication && mobile) && (topBannerData === false || (topBannerData && topBannerData.mobile !== mobile))) {
			setData('topBanner', null);
			siteServices.getBanners(mobile ? 13 : 10).then((bannerData) => {
				setData('topBannerData', {
					mobile: mobile,
					data: bannerData.length > 0 ? bannerData[0] : false,
				});
			}).catch(() => {
				setData('topBannerData', {
					mobile: mobile,
					data: false,
				});
			})
		}
	}, [activeRoute, topBannerData, mobile, isApplication])

	if(!(isApplication && mobile) && topBannerData?.data && activeRoute?.key === 'home') {
		return (
			<>
				{(topBannerData.data.link && topBannerData.data.link.length > 0) ?
					<Link
						raw
						href={topBannerData.data?.link}
						className="header-topbanner">
						<Img cover className="topbanner-image" src={topBannerData.data?.image_url} />
					</Link>
					:
					<div className="header-topbanner">
						<Img cover className="topbanner-image" src={topBannerData.data?.image_url} />
					</div>
				}
			</>
		)
	}
	else {
		return null;
	}
}

export default TopBanner;